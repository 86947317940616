import React, { useEffect, useState } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Grid from '@material-ui/core/Grid';
import { ButtonBase } from '@material-ui/core';
import { CSSProperties } from '@material-ui/core/styles/withStyles';
import ShowIf from 'components/views/ShowIf';
import { DialogPage } from 'components/common/DialogPage';
import { OrdersPrekursorDownloadIcon } from 'components/common/BottomNav/nav-icons/orders-icon/orders-prekursor-download-icon';
import { useDispatch, useSelector } from 'react-redux';
import { clearSPDocument, getSPDocument } from 'store/reducers/Orders/actions';
import { SpDocumentType } from 'store/reducers/Orders/constants';

import { AnalyticsEvent, makeAnalyticsRequest } from 'utils/Analytics/Segment';
import { isWebview } from 'utils/helpers/check-platform';
import { DataObject } from 'types';
import { Order, OrderDetails } from 'store/reducers/Orders';
import { State } from 'store';
import { S } from './RegularSPDocumentDownload.style';
import { TranslateProps } from '../../../../../utils/Localization/types';

export interface Props {
  order: OrderDetails | Order | DataObject<any>;
  style?: CSSProperties;
  digitalSignatureVerified?: boolean;
}

export const RegularSPDocumentDownload: React.FC<React.PropsWithChildren<Props & TranslateProps>> = (props) => {
  const { t, style, order, digitalSignatureVerified = false } = props;

  const [showInstruction, setShowInstruction] = useState(false);

  const dispatch = useDispatch();

  const checkSPDocument = async () => {
    await dispatch(getSPDocument(order.id, SpDocumentType.REGULAR));
  };

  const { spDocumentUrl, spLoading } = useSelector((state: State) => state.orders);

  useEffect(() => {
    if (spDocumentUrl) {
      const anchor = document.createElement('a');
      anchor.href = spDocumentUrl;
      anchor.download = `SP_${order.pharmacy_id}_${order.po_number}.pdf`;
      if (!isWebview()) anchor.target = '_blank';
      anchor.click();
      const analyticName =
        order.requirements?.length > 0 ? 'pt_medicaldevices_download_document' : 'pt_regular_download_document';
      makeAnalyticsRequest(analyticName, AnalyticsEvent.Track, {
        pharmacyId: order.pharmacy_id,
        poNumber: order.po_number,
        status: order.precursor_status?.status,
      });
    }
    return (): any => dispatch(clearSPDocument());
  }, [spDocumentUrl, dispatch]);

  return (
    <div>
      <Card square className="header" elevation={0} style={style}>
        <CardContent>
          <S.CardHeader item xs container alignItems="flex-end" justify="flex-end">
            <Grid container alignItems="center" justify="space-between">
              <Grid item xs={9}>
                <ButtonBase onClick={() => setShowInstruction(true)}>
                  <S.UploadInstructionInfoIcon />
                  <S.UploadInstructionText>{t('spDocumentNonPrekursor.info')}</S.UploadInstructionText>
                </ButtonBase>
              </Grid>
              <Grid item xs={3}>
                <S.UploadInstructionArrowIcon onClick={() => setShowInstruction(true)} />
              </Grid>
            </Grid>
          </S.CardHeader>
          <Grid className="download-template" container justifyContent="center" direction="row">
            <Grid item style={{ textAlign: 'center' }} xs={12}>
              <S.DownloadButton disabled={spLoading} onClick={() => checkSPDocument()}>
                <S.DownloadIconStyle>
                  <OrdersPrekursorDownloadIcon />
                </S.DownloadIconStyle>
                {digitalSignatureVerified
                  ? t('spDocumentNonPrekursor.downloadESP')
                  : t('spDocumentNonPrekursor.downloadSPSample')}
              </S.DownloadButton>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <ShowIf condition={showInstruction}>
        <DialogPage
          open={showInstruction}
          link="/id/how-to-download-sp-document"
          close={() => setShowInstruction(false)}
        />
      </ShowIf>
    </div>
  );
};
