import * as Redux from 'redux';
import * as constants from './constants';
import { Actions } from './actions';

export interface FavoriteRequest {
  page: number;
  limit: number;
  query?: string;
  product_ids: number[];
}

export interface Distributor {
  id: number;
  name: string;
}

export interface PharmacyProduct {
  localeCompare(b: PharmacyProduct): number;
  id: number;
  name: string;
  packaging: string;
  molecule: string;
  selling_price: number;
  discount_rate: number;
  display_photo: string;
  thumbnail_photo: string;
  sku_code: string;
  flag: string;
  net_price: number;
  promoted: boolean;
  distributor: Distributor;
  remaining_quantity: number;
  quantity_threshold: number;
  low_stock_threshold: number;
  available: boolean;
  distributor_product_id: number;
  tier_discount: TierDiscount[];
  favorited: boolean;
  favorite_product_id: number;
  marketing_id?: number | string;
}

export interface TierDiscount {
  id: number;
  distributor_product_id: number;
  min: number;
  max: number;
  discount_rate: number;
  price: number;
  available: number;
  distributor: Distributor;
}

export interface DelayedFavoriteRequest {
  productId: number;
  favoriteId: number;
}

export interface State {
  products: PharmacyProduct[];
  delayedFavoriteReq: DelayedFavoriteRequest[];
  delayedRemoveFavoriteReq: DelayedFavoriteRequest[];
  limit: number;
  nextPage: number;
  loading: boolean;
  hasMore: boolean;
  initialProductLength: number | null;
}

const InitialState: State = {
  products: [],
  delayedFavoriteReq: [],
  delayedRemoveFavoriteReq: [],
  limit: 20,
  nextPage: 1,
  loading: true,
  hasMore: false,
  initialProductLength: null,
};

const FavoriteReducer: Redux.Reducer<State, Actions> = (state = InitialState, action) => {
  switch (action.type) {
    case constants.GET_FAVORITES: {
      const products = action.products.sort((a, b) => a.name.localeCompare(b.name));
      return {
        ...state,
        loading: false,
        limit: action.limit,
        nextPage: action.nextPage,
        products,
        hasMore: action.hasMore,
        initialProductLength: action.initialProductLength,
      };
    }
    case constants.REMOVE_FROM_FAVORITE_PRODUCT:
      return {
        ...state,
        products: action.products,
      };
    case constants.CLEAR_FAVORITES:
      return {
        ...state,
        ...InitialState,
      };

    case constants.ADD_DELAYED_FAVOTITE_REQUEST:
      return {
        ...state,
        delayedFavoriteReq: action.delayedFavoriteReq,
      };

    case constants.ADD_DELAYED_REMOVE_FAVORITE_REQUEST:
      return {
        ...state,
        delayedRemoveFavoriteReq: action.delayedRemoveFavoriteReq,
      };

    case constants.CLEAR_DELAYED_FAVORITE_REQUEST:
      return {
        ...state,
        delayedFavoriteReq: [],
        delayedRemoveFavoriteReq: [],
      };

    default:
      return state;
  }
};

export default FavoriteReducer;
