import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { translate } from 'react-i18next';
import { setCloseSnackbarCart } from 'store/reducers/Utils/actions';
import { getMarketingFeatures } from 'store/reducers/MarketingFeatures/actions';
import FavoritesPage, { StateProps } from './component';

import { State as StoreState } from '../../../store';
import {
  clearFavoriteProducts,
  fetchFavoriteProducts,
  removeFromFavoriteProduct,
  clearDelayedFavoriteRequest,
} from '../../../store/reducers/FavoriteProduct/actions';
import { fetchTours } from '../../../store/reducers/Tour/actions';

const mapStateToProps = (state: StoreState): StateProps => ({
  favorites: state.favorites,
  loading: state.favorites.loading,
  hasMore: state.favorites.hasMore,
  tours: state.tours,
  snackbarCart: state.utils.snackbarCart,
});

const mapDispatchToProps = {
  fetchFavoriteProducts,
  removeFromFavoriteProduct,
  clearFavoriteProducts,
  fetchTours,
  clearDelayedFavoriteRequest,
  setCloseSnackbarCart,
  getMarketingFeatures,
};

export default translate('favoritesPage')(withRouter(connect(mapStateToProps, mapDispatchToProps)(FavoritesPage)));
